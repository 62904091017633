import { en } from 'vuetify/src/locale'
import { request } from '@/utils/request'

export default async () => {
  // Загрузим условно сегодняшнюю версию словаря переводов СДО.
  const sdoTranslations = await request('/assets/translations/en.json?_ts=' + (new Date()).toISOString().substring(0, 10))

  return {
    $vuetify: {
      ...en
    },

    accept_attempt: {
      one: 'Accept this attempt'
    },

    accounts: {
      one: 'Account',
      two: 'Accounts',
      many: 'Accounts',
      other: 'Accounts',
      genitive_one: 'Account',
      genitive: 'Accounts',
      dative_one: 'Account',
      dative: 'Accounts',
      accusative_one: 'Account',
      accusative: 'Accounts',
      prepositional_one: 'Accounts',
      prepositional: 'Accounts'
    },

    add: {
      one: 'Add'
    },

    address: {
      one: 'Address'
    },

    address_of_a_person: {
      one: 'Registration address'
    },

    again: {
      one: 'Try again'
    },

    all: {
      one: 'All',
      other: 'All'
    },

    anonymous_first_question: {
      one: 'For the first time with us?'
    },

    anonymous_forgot_password: {
      one: 'Forgot your password?'
    },

    anonymous_register: {
      one: 'Register an account'
    },

    anonymous_reset_password: {
      one: 'Reset password'
    },

    anonymous_sign_in: {
      one: 'Log In'
    },

    anonymous_sign_out: {
      one: 'Log out'
    },

    anonymous_your_email: {
      one: 'Your e-mail address'
    },

    anonymous_your_login_or_email: {
      one: 'Your login or e-mail address'
    },

    answers: {
      one: 'Answer',
      two: 'Answers',
      many: 'Answers',
      other: 'Answers',
      genitive_one: 'Answer',
      genitive: 'Answers',
      dative_one: 'Answer',
      dative: 'Answers',
      accusative_one: 'Answer',
      accusative: 'Answers',
      prepositional_one: 'Answer',
      prepositional: 'Answers'
    },

    attach_files: {
      one: 'Attach file',
      other: 'Attach files'
    },

    attempts: {
      one: 'Attempt',
      two: 'Attempts',
      many: 'Attempts',
      other: 'Attempts',
      genitive_one: 'Attempt',
      genitive: 'Attempts',
      dative_one: 'Attempt',
      dative: 'Attempts',
      accusative_one: 'Attempt',
      accusative: 'Attempts',
      prepositional_one: 'Attempt',
      prepositional: 'Attempts'
    },

    attempts_left_total: {
      one: 'You have {count} of {all} {unit} left to complete this task'
    },

    attempts_left_today: {
      one: 'You have {count} of {all} {unit} attempts today'
    },

    available: {
      one: 'Available'
    },

    back: {
      one: 'Back'
    },

    bank_address: {
      one: 'Address of the bank'
    },

    bank_name: {
      one: 'Name of the bank'
    },

    bik: {
      one: 'BIC'
    },

    cancel: {
      one: 'Cancel'
    },

    certificate_full_name: {
      one: 'Full name in the document'
    },

    certificates: {
      one: 'Certificate',
      two: 'Certificates',
      many: 'Certificates',
      other: 'Certificates',
      genitive_one: 'Certificate',
      genitive: 'Certificates',
      dative_one: 'Certificate',
      dative: 'Certificates',
      accusative_one: 'Certificate',
      accusative: 'Certificates',
      prepositional_one: 'Certificate',
      prepositional: 'Certificates'
    },

    citizenship: {
      one: 'Citizenship',
      two: 'Citizenships',
      many: 'Citizenships',
      other: 'Citizenships',
      genitive_one: 'Citizenship',
      genitive: 'Citizenships',
      dative_one: 'Citizenship',
      dative: 'Citizenships',
      accusative_one: 'Citizenship',
      accusative: 'Citizenships',
      prepositional_one: 'Citizenship',
      prepositional: 'Citizenships'
    },

    close: {
      one: 'Close'
    },

    complete: {
      one: 'Complete'
    },

    complete_training: {
      one: 'Complete the training'
    },

    completed_at: {
      one: 'Completed at'
    },

    continue: {
      one: 'Continue'
    },

    continue_training: {
      one: 'Continue training'
    },

    contractors: {
      one: 'Contractor',
      two: 'Contractors',
      many: 'Contractors',
      other: 'Contractors',
      genitive_one: 'Contractor',
      genitive: 'Contractors',
      dative_one: 'Contractor',
      dative: 'Contractors',
      accusative_one: 'Contractor',
      accusative: 'Contractors',
      prepositional_one: 'Contractor',
      prepositional: 'Contractors'
    },

    courses: {
      one: 'Course',
      two: 'Courses',
      many: 'Courses',
      other: 'Courses',
      genitive_one: 'Course',
      genitive: 'Courses',
      dative_one: 'Course',
      dative: 'Courses',
      accusative_one: 'Course',
      accusative: 'Courses',
      prepositional_one: 'Course',
      prepositional: 'Courses'
    },

    created: {
      one: 'Created',
      other: 'Created'
    },

    delete: {
      one: 'Delete'
    },

    descriptions: {
      one: 'Description',
      two: 'Descriptions',
      many: 'Descriptions',
      other: 'Descriptions',
      genitive_one: 'Description',
      genitive: 'Descriptions',
      dative_one: 'Description',
      dative: 'Descriptions',
      accusative_one: 'Description',
      accusative: 'Descriptions',
      prepositional_one: 'Description',
      prepositional: 'Descriptions'
    },

    director: {
      one: 'Director',
      two: 'Directors',
      many: 'Directors',
      other: 'Directors',
      genitive_one: 'Director',
      genitive: 'Directors',
      dative_one: 'Director',
      dative: 'Directors',
      accusative_one: 'Director',
      accusative: 'Directors',
      prepositional_one: 'Director',
      prepositional: 'Directors'
    },

    dob: {
      one: 'Date of birth',
      two: 'Dates of birth',
      many: 'Dates of birth',
      other: 'Dates of birth',
      genitive_one: 'Date of birth',
      genitive: 'Dates of birth',
      dative_one: 'Date of birth',
      dative: 'Dates of birth',
      accusative_one: 'Date of birth',
      accusative: 'Dates of birth',
      prepositional_one: 'Date of birth',
      prepositional: 'Dates of birth'
    },

    documents: {
      one: 'Document',
      two: 'Documents',
      many: 'Documents',
      other: 'Documents',
      genitive_one: 'Document',
      genitive: 'Documents',
      dative_one: 'Document',
      dative: 'Documents',
      accusative_one: 'Document',
      accusative: 'Documents',
      prepositional_one: 'Document',
      prepositional: 'Documents'
    },

    edit: {
      one: 'Edit'
    },

    education_level: {
      one: 'Level of education'
    },

    educational_institution: {
      one: 'Educational institution'
    },

    educational_institution_level: {
      one: 'Level of educational institution'
    },

    emails: {
      one: 'E-mail',
      two: 'E-mails',
      many: 'E-mails',
      other: 'E-mails',
      genitive_one: 'E-mail',
      genitive: 'E-mails',
      dative_one: 'E-mail',
      dative: 'E-mails',
      accusative_one: 'E-mail',
      accusative: 'E-mails',
      prepositional_one: 'E-mail',
      prepositional: 'E-mails'
    },

    employers: {
      one: 'Place of work',
      two: 'Places of work',
      many: 'Places of work',
      other: 'Places of work',
      genitive_one: 'Place of work',
      genitive: 'Places of work',
      dative_one: 'Place of work',
      dative: 'Places of work',
      accusative_one: 'Place of work',
      accusative: 'Places of work',
      prepositional_one: 'Place of work',
      prepositional: 'Places of work'
    },

    essays: {
      one: 'Practical task',
      two: 'Practical tasks',
      many: 'Practical tasks',
      other: 'Practical tasks',
      genitive_one: 'Practical task',
      genitive: 'Practical tasks',
      dative_one: 'Practical task',
      dative: 'Practical tasks',
      accusative_one: 'Practical task',
      accusative: 'Practical tasks',
      prepositional_one: 'Practical task',
      prepositional: 'Practical tasks'
    },

    exercise: {
      one: 'Practice',
      other: 'Practice'
    },

    files: {
      one: 'File',
      two: 'Files',
      many: 'Files',
      other: 'Files',
      genitive_one: 'File',
      genitive: 'Files',
      dative_one: 'File',
      dative: 'Files',
      accusative_one: 'File',
      accusative: 'Files',
      prepositional_one: 'File',
      prepositional: 'Files'
    },

    final_test_possible_complete_only: {
      one: 'The final mandatory test or practical task can be completed'
    },

    final_test_possible_was_complete_only: {
      one: 'The final mandatory test or practical task could be completed'
    },

    final_test_possible_will_be_complete_only: {
      one: 'The final mandatory test or practical task can be completed'
    },

    form_filed_desc_free_form: {
      one: 'in free form'
    },

    from: {
      one: 'from'
    },

    full_name_person: {
      one: 'Full name'
    },

    gender: {
      one: 'Sex'
    },

    gender_female: {
      one: 'Female'
    },
    gender_male: {
      one: 'Male'
    },

    general_questions: {
      one: 'General issue',
      two: 'General issues',
      many: 'General issues',
      other: 'General issues',
      genitive_one: 'General issue',
      genitive: 'General issues',
      dative_one: 'General issue',
      dative: 'General issues',
      accusative_one: 'General issue',
      accusative: 'General issues',
      prepositional_one: 'General issue',
      prepositional: 'General issues'
    },

    generate_new_password: {
      one: 'Generate new password'
    },

    groups: {
      one: 'Course group',
      two: 'Course groups',
      many: 'Course groups',
      other: 'Course groups',
      genitive_one: 'Course group',
      genitive: 'Course groups',
      dative_one: 'Course group',
      dative: 'Course groups',
      accusative_one: 'Course group',
      accusative: 'Course groups',
      prepositional_one: 'Course group',
      prepositional: 'Course groups'
    },

    help_center: {
      one: 'Help Center'
    },

    help_not_found: {
      one: 'Help not found'
    },

    in_accusative_case: {
      one: 'in the accusative case'
    },

    in_dative_case: {
      one: 'in the dative case'
    },

    in_genitive_case: {
      one: 'in the genitive case'
    },

    information_doc_education_of_the_user: {
      one: 'Information on user’s educational documents'
    },

    information_education: {
      one: 'Information on education'
    },

    inn: {
      one: 'INN'
    },

    interface_language: {
      one: 'Interface language'
    },

    kpp: {
      one: 'IEC'
    },

    ks: {
      one: 'Correspondent account'
    },

    last_attempt_left_today: {
      one: 'Last attempt left for today'
    },

    last_attempt_left_total: {
      one: 'Last attempt left'
    },

    lectures: {
      one: 'Lecture',
      two: 'Lectures',
      many: 'Lectures',
      other: 'Lectures',
      genitive_one: 'Lecture',
      genitive: 'Lectures',
      dative_one: 'Lecture',
      dative: 'Lectures',
      accusative_one: 'Lecture',
      accusative: 'Lectures',
      prepositional_one: 'Lecture',
      prepositional: 'Lectures'
    },

    left: {
      one: 'Left'
    },

    lessons: {
      one: 'Lesson',
      two: 'Lessons',
      many: 'Lessons',
      other: 'Lessons',
      genitive_one: 'Lesson',
      genitive: 'Lessons',
      dative_one: 'Lesson',
      dative: 'Lessons',
      accusative_one: 'Lesson',
      accusative: 'Lessons',
      prepositional_one: 'Lesson',
      prepositional: 'Lessons'
    },

    list_lessons: {
      one: 'List of lessons',
      two: 'Lists of lessons',
      many: 'Lists of lessons',
      other: 'Lists of lessons',
      genitive_one: 'List of lessons',
      genitive: 'Lists of lessons',
      dative_one: 'List of lessons',
      dative: 'Lists of lessons',
      accusative_one: 'List of lessons',
      accusative: 'Lists of lessons',
      prepositional_one: 'List of lessons',
      prepositional: 'Lists of lessons'
    },

    loading_please_wait: {
      one: 'Loading please wait'
    },

    locality: {
      one: 'City of registration'
    },

    locality_of_a_person: {
      one: 'City of registration or residence'
    },

    ls: {
      one: 'Personal account'
    },

    managers: {
      one: 'Manager',
      two: 'Managers',
      many: 'Managers',
      other: 'Managers',
      genitive_one: 'Manager',
      genitive: 'Managers',
      dative_one: 'Manager',
      dative: 'Managers',
      accusative_one: 'Manager',
      accusative: 'Managers',
      prepositional_one: 'Manager',
      prepositional: 'Managers'
    },

    marks: {
      one: 'Rating',
      two: 'Ratings',
      many: 'Ratings',
      other: 'Ratings',
      genitive_one: 'Rating',
      genitive: 'Ratings',
      dative_one: 'Rating',
      dative: 'Ratings',
      accusative_one: 'Rating',
      accusative: 'Ratings',
      prepositional_one: 'Rating',
      prepositional: 'Ratings'
    },

    meeting_status_accepted: {
      one: 'Scheduled'
    },

    meeting_status_canceled: {
      one: 'Canceled'
    },

    meeting_status_completed: {
      one: 'Completed'
    },

    meeting_status_pending: {
      one: 'Not processed'
    },

    meeting_status_started: {
      one: 'In progress'
    },

    meetings: {
      one: 'Event',
      two: 'Events',
      many: 'Events',
      other: 'Events',
      genitive_one: 'Event',
      genitive: 'Events',
      dative_one: 'Event',
      dative: 'Events',
      accusative_one: 'Event',
      accusative: 'Events',
      prepositional_one: 'Event',
      prepositional: 'Events'
    },

    messages: {
      one: 'Message',
      two: 'Messages',
      many: 'Messages',
      other: 'Messages',
      genitive_one: 'Message',
      genitive: 'Messages',
      dative_one: 'Message',
      dative: 'Messages',
      accusative_one: 'Message',
      accusative: 'Messages',
      prepositional_one: 'Message',
      prepositional: 'Messages'
    },

    messages_empty_message: {
      one: 'You have no posts yet. If you have any difficulties with the training or operation of the system'
    },

    messages_general_question: {
      one: 'Write a general issue'
    },

    minutes: {
      one: 'minute',
      two: 'minutes',
      many: 'minutes',
      other: 'minutes',
      genitive_one: 'minute',
      genitive: 'minutes',
      dative_one: 'minute',
      dative: 'minutes',
      accusative_one: 'minute',
      accusative_two: 'minutes',
      accusative_many: 'minutes',
      accusative: 'minutes',
      prepositional_one: 'minute',
      prepositional: 'minutes'
    },

    new_attempts: {
      one: 'A new attempt'
    },

    next_lesson: {
      one: 'Next lesson'
    },

    next_question: {
      one: 'Next question'
    },

    no: {
      one: 'no'
    },

    no_attempts_left_today: {
      one: 'You have no attempts left to complete this task today. Try tomorrow'
    },

    no_attempts_left_total: {
      one: 'You have no more attempts left to complete this task'
    },

    no_training: {
      one: 'Training is not permitted'
    },

    not_specified: {
      one: 'Not indicated'
    },

    numbers: {
      one: 'Number',
      two: 'Numbers',
      many: 'Numbers',
      other: 'Numbers',
      genitive_one: 'Number',
      genitive: 'Numbers',
      dative_one: 'Number',
      dative: 'Numbers',
      accusative_one: 'Number',
      accusative: 'Numbers',
      prepositional_one: 'Number',
      prepositional: 'Numbers'
    },

    ogrn: {
      one: 'OGRN'
    },

    okpo: {
      one: 'OKPO'
    },

    oktmo: {
      one: 'OKTMO'
    },

    okved: {
      one: 'OKVED'
    },

    online_shop: {
      one: 'Online shop',
      other: 'Online shop',
      genitive_one: 'Online shop',
      genitive: 'Online shop',
      dative_one: 'Online shop',
      dative: 'Online shop',
      accusative_one: 'Online shop',
      accusative: 'Online shop',
      prepositional_one: 'Online shop',
      prepositional: 'Online shop'
    },

    optional: {
      one: 'Optional'
    },

    order_management: {
      one: 'Order management'
    },

    out_of: {
      one: 'of'
    },

    passwords: {
      one: 'Password',
      two: 'Passwords',
      many: 'Passwords',
      other: 'Passwords',
      genitive_one: 'Password',
      genitive: 'Passwords',
      dative_one: 'Password',
      dative: 'Passwords',
      accusative_one: 'Password',
      accusative: 'Passwords',
      prepositional_one: 'Password',
      prepositional: 'Passwords'
    },

    periods: {
      one: 'Period',
      two: 'Periods',
      many: 'Periods',
      other: 'Periods',
      genitive_one: 'Period',
      genitive: 'Periods',
      dative_one: 'Period',
      dative: 'Periods',
      accusative_one: 'Period',
      accusative: 'Periods',
      prepositional_one: 'Period',
      prepositional: 'Periods'
    },

    personal_data_processing_policy: {
      one: 'Personal Data Processing Policy'
    },

    phones: {
      one: 'Phone',
      two: 'Phones',
      many: 'Phones',
      other: 'Phones',
      genitive_one: 'Phone',
      genitive: 'Phones',
      dative_one: 'Phone',
      dative: 'Phones',
      accusative_one: 'Phone',
      accusative: 'Phones',
      prepositional_one: 'Phone',
      prepositional: 'Phones'
    },

    physical_address_of_a_person: {
      one: 'Residential address'
    },

    please_enter_answer: {
      one: 'Please, select answer option(-s)!'
    },

    points_count_of: {
      one: 'You scored {count} of {all} required points'
    },

    positive_score: {
      one: 'Positive score, not less'
    },

    positive_score_of: {
      one: 'Passing score, not less than: {count} of {all}'
    },

    postal_address_of_a_person: {
      one: 'Mailing address'
    },

    previous_lesson: {
      one: 'Previous lesson'
    },

    previous_question: {
      one: 'Previous question'
    },

    print: {
      one: 'Print'
    },

    profiles: {
      one: 'Profile',
      two: 'Profiles',
      many: 'Profiles',
      other: 'Profiles',
      genitive_one: 'Profile',
      genitive: 'Profiles',
      dative_one: 'Profile',
      dative: 'Profiles',
      accusative_one: 'Profile',
      accusative: 'Profiles',
      prepositional_one: 'Profile',
      prepositional: 'Profiles'
    },

    questions: {
      one: 'Question',
      two: 'Questions',
      many: 'Questions',
      other: 'Questions',
      genitive_one: 'Question',
      genitive: 'Questions',
      dative_one: 'Question',
      dative: 'Questions',
      accusative_one: 'Question',
      accusative: 'Questions',
      prepositional_one: 'Question',
      prepositional: 'Questions'
    },

    question_may_have_several_answers: {
      one: 'This question can have several answer options'
    },

    question_requires_to_put_the_answer_options_in_the_correct_order: {
      one: 'For this question, you need to put the answer options in the correct order'
    },

    quizzes: {
      one: 'Quiz',
      two: 'Quizzes',
      many: 'Quizzes',
      other: 'Quizzes',
      genitive_one: 'Quiz',
      genitive: 'Quizzes',
      dative_one: 'Quiz',
      dative: 'Quizzes',
      accusative_one: 'Quiz',
      accusative: 'Quizzes',
      prepositional_one: 'Quiz',
      prepositional: 'Quizzes'
    },

    questions_and_variants_of_answers: {
      one: 'Questions and answers'
    },

    read: {
      one: 'Read'
    },

    read_again: {
      one: 'Read again'
    },

    remove: {
      one: 'Remove'
    },

    reply: {
      one: 'Reply'
    },

    results: {
      one: 'Result',
      two: 'Results',
      many: 'Results',
      other: 'Results',
      genitive_one: 'Result',
      genitive: 'Results',
      dative_one: 'Result',
      dative: 'Results',
      accusative_one: 'Result',
      accusative: 'Results',
      prepositional_one: 'Result',
      prepositional: 'Results'
    },

    required: {
      one: 'Mandatory'
    },

    rs: {
      one: 'Payment account'
    },

    save: {
      one: 'Save'
    },

    scores_for_question: {
      one: 'Points scored for the question:'
    },

    scorm: {
      one: 'SCORM'
    },

    search: {
      one: 'Search'
    },

    send: {
      one: 'Send'
    },

    series: {
      one: 'Series'
    },

    services: {
      one: 'Service',
      two: 'Services',
      many: 'Services',
      other: 'Services',
      genitive_one: 'Service',
      genitive: 'Services',
      dative_one: 'Service',
      dative: 'Services',
      accusative_one: 'Service',
      accusative: 'Services',
      prepositional_one: 'Service',
      prepositional: 'Services'
    },

    session_ends_at: {
      one: 'Training ends at'
    },

    session_starts_at: {
      one: 'Training starts at'
    },

    session_status_accepted: {
      one: 'Training completed successfully'
    },

    session_status_completed: {
      one: 'Training completed'
    },

    session_status_evaluated: {
      one: 'Training failed'
    },

    session_status_pending: {
      one: 'Training has not yet started'
    },

    session_status_rejected: {
      one: 'Training failed'
    },

    session_status_skipped: {
      one: 'Training skipped'
    },

    session_status_started: {
      one: 'Training is in progress'
    },

    sessions: {
      one: 'Training program',
      two: 'Training programs',
      many: 'Training programs',
      other: 'Training programs',
      genitive_one: 'Training program',
      genitive: 'Training programs',
      dative_one: 'Training programs',
      dative: 'Training programs',
      accusative_one: 'Training program',
      accusative: 'Training programs',
      prepositional_one: 'Training program',
      prepositional: 'Training programs'
    },

    show_in_fullscreen: {
      one: 'Expand to full screen'
    },

    signed_at: {
      one: 'Date of signing'
    },

    skip: {
      one: 'Skip'
    },

    slides: {
      one: 'Slide',
      two: 'Slides',
      many: 'Slides',
      other: 'Slides',
      genitive_one: 'Slide',
      genitive: 'Slides',
      dative_one: 'Slide',
      dative: 'Slides',
      accusative_one: 'Slide',
      accusative: 'Slides',
      prepositional_one: 'Slide',
      prepositional: 'Slides'
    },

    snils: {
      one: 'SNILS'
    },

    speakers: {
      one: 'Host',
      two: 'Hosts',
      many: 'Hosts',
      other: 'Hosts',
      genitive_one: 'Host',
      genitive: 'Hosts',
      dative_one: 'Host',
      dative: 'Hosts',
      accusative_one: 'Host',
      accusative: 'Hosts',
      prepositional_one: 'Host',
      prepositional: 'Hosts'
    },

    start_training: {
      one: 'Start training'
    },

    start: {
      one: 'Start'
    },

    started_at: {
      one: 'Started at'
    },

    statuses: {
      one: 'Status',
      two: 'Statuses',
      many: 'Statuses',
      other: 'Statuses',
      genitive_one: 'Status',
      genitive: 'Statuses',
      dative_one: 'Status',
      dative: 'Statuses',
      accusative_one: 'Status',
      accusative: 'Statuses',
      prepositional_one: 'Status',
      prepositional: 'Statuses'
    },

    step_is_available_at: {
      one: 'This assignment is available'
    },

    step_status_accepted: {
      one: 'Completed correctly'
    },

    step_status_completed: {
      one: 'Sent for evaluation'
    },

    step_status_evaluated: {
      one: 'Completed incorrectly'
    },

    step_status_pending: {
      one: 'Not started yet'
    },

    step_status_rejected: {
      one: 'Completed incorrectly'
    },

    step_status_skipped: {
      one: 'Training skipped'
    },

    step_status_started: {
      one: 'Training is in progress'
    },

    step_was_available_at: {
      one: 'This task could be completed'
    },

    step_will_be_available_at: {
      one: 'This task can be completed'
    },

    students_record_book: {
      one: 'Training history'
    },

    subjects: {
      one: 'Subject',
      two: 'Subjects',
      many: 'Subjects',
      other: 'Subjects',
      genitive_one: 'Subject',
      genitive: 'Subjects',
      dative_one: 'Subject',
      dative: 'Subjects',
      accusative_one: 'Subject',
      accusative: 'Subjects',
      prepositional_one: 'Subject',
      prepositional: 'Subjects'
    },

    submit: {
      one: 'Ready'
    },

    summative_lessons: {
      one: 'Final (estimated) lesson',
    },

    surveys: {
      one: 'Survey',
      two: 'Surveys',
      many: 'Surveys',
      other: 'Surveys',
      genitive_one: 'Survey',
      genitive: 'Surveys',
      dative_one: 'Survey',
      dative: 'Surveys',
      accusative_one: 'Survey',
      accusative: 'Surveys',
      prepositional_one: 'Survey',
      prepositional: 'Surveys'
    },

    tasks: {
      one: 'Task',
      two: 'Tasks',
      many: 'Tasks',
      other: 'Tasks',
      genitive_one: 'Task',
      genitive: 'Tasks',
      dative_one: 'Task',
      dative: 'Tasks',
      accusative_one: 'Task',
      accusative: 'Tasks',
      prepositional_one: 'Task',
      prepositional: 'Tasks'
    },

    teachers: {
      one: 'Teacher',
      two: 'Teachers',
      many: 'Teachers',
      other: 'Teachers',
      genitive_one: 'Teacher',
      genitive: 'Teachers',
      dative_one: 'Teacher',
      dative: 'Teachers',
      accusative_one: 'Teacher',
      accusative: 'Teachers',
      prepositional_one: 'Teacher',
      prepositional: 'Teachers'
    },

    textbooks: {
      one: 'Textbook',
      two: 'Textbooks',
      many: 'Textbooks',
      other: 'Textbooks',
      genitive_one: 'Textbook',
      genitive: 'Textbooks',
      dative_one: 'Textbook',
      dative: 'Textbooks',
      accusative_one: 'Textbook',
      accusative: 'Textbooks',
      prepositional_one: 'Textbook',
      prepositional: 'Textbooks'
    },

    time_to_attempt: {
      one: 'Attempt time'
    },

    to: {
      one: 'to'
    },

    training_completed_at: {
      one: 'Training completed'
    },

    training_possible_only: {
      one: 'Training possible only'
    },

    training_possible_was_only: {
      one: 'Training possible was be only'
    },

    training_possible_will_be_only: {
      one: 'Training possible will be only'
    },

    training_started_at: {
      one: 'Training started'
    },

    types: {
      one: 'Type',
      two: 'Types',
      many: 'Types',
      other: 'Types'
    },

    videos: {
      one: 'Video',
      two: 'Videos',
      many: 'Videos',
      other: 'Videos',
      genitive_one: 'Video',
      genitive: 'Videos',
      dative_one: 'Video',
      dative: 'Videos',
      accusative_one: 'Video',
      accusative: 'Videos',
      prepositional_one: 'Video',
      prepositional: 'Videos'
    },

    update: {
      one: 'Update'
    },

    user_profile_passport_info: {
      one: 'Passport details',
      other: 'Passport details',
      genitive_one: 'Passport details',
      genitive: 'Passport details',
      dative_one: 'Passport details',
      dative: 'Passport details',
      accusative_one: 'Passport details',
      accusative: 'Passport details',
      prepositional_one: 'Passport details',
      prepositional: 'Passport details'
    },

    user_profile_passport_info_placeholder: {
      one: 'Series, number, by whom and when issued'
    },

    user_profile_position: {
      one: 'Position',
      two: 'Positions',
      many: 'Positions',
      other: 'Positions',
      genitive_one: 'Position',
      genitive: 'Position',
      dative_one: 'Positions',
      dative: 'Positions',
      accusative_one: 'Position',
      accusative: 'Position',
      prepositional_one: 'Position',
      prepositional: 'Positions'
    },

    usernames: {
      one: 'Login',
      two: 'Logins',
      many: 'Logins',
      other: 'Logins',
      genitive_one: 'Login',
      genitive: 'Logins',
      dative_one: 'Login',
      dative: 'Logins',
      accusative_one: 'Login',
      accusative: 'Logins',
      prepositional_one: 'Login',
      prepositional: 'Logins'
    },

    write_to_us: {
      one: 'write to us'
    },

    yes: {
      one: 'yes'
    },

    you_get_maximum_of_100_scores: {
      one: 'You get maximum 100 points per one answer'
    },

    $app: {
      loading: 'Loading...',
      expand_list_of_lessons: 'Expand the list of lessons',
      no_lessons: 'No lessons',
      exit: 'Exit',
      no_data: 'No data',
      upload_photo: 'Upload photo',
      delete_photo: 'Delete photo',
      page_not_found: 'Page not found',
      nothing_selected: 'Nothing selected',

      menuItem: {
        groups: 'Groups',
        infobase: 'Library',
        library: 'Library'
      },

      action: {
        one: 'Action'
      },

      all_fields_required: 'All fields are required',

      ask_question: {
        one: 'Ask a question'
      },

      audio_recorder: {
        title: 'Audio recording',
        start: 'Start recording',
        stop: 'Stop recording',
        progress: 'Speak...',
        error: 'Could not find microphone'
      },

      basic_information: 'Basic information',

      changes_saved: 'Changes saved!',

      chat: {
        delete_confirm: 'Are you sure you want to delete this chat?',
        select_title: 'Select a chat'
      },

      chat_create: {
        title: 'New message',
        filesize_msg: 'Attention! No more than {n} MB per file!',
        action_title: 'Write a new message'
      },

      choose_answer_on_scale: 'Choose the answer on the scale',

      choose_one_answer: {
        one: 'Choose one answer option'
      },

      contact_information: 'Сontact information',

      continue_survey: 'Continue survey',

      completed: 'Сompleted',

      create_new: 'Create a new',

      create_new_chat: 'Create a new chat',

      date_completion: {
        one: 'Date of completion'
      },

      default_message_text: "Hi, I'd like to ask about lesson",

      education: 'Education',

      final_lesson: {
        one: 'Final (estimated) lesson'
      },

      full_name: {
        accusative: 'Full name in the accusative case',
        dative: 'Full name in the dative case',
        genitive: 'Full name in the genitive case',
        latin: 'Full name in Latin',
        respective: 'Full name: respective forms'
      },

      go_management: 'Switch to order management mode',

      hide: 'Hide',

      lesson_status: {
        one: 'Lesson status'
      },

      lesson_status_accepted: 'Accepted',

      lesson_status_completed: 'Sent for evaluation',

      lesson_status_rejected: 'Done incorrectly',

      lesson_status_started: 'In progress',

      lesson_type: {
        one: 'Lesson type'
      },

      mark_action_not_rework: 'Rework is not permitted',

      mark_action_required: 'Mandatory to be done',

      mark_action_rework: 'Rework is required',

      mark_action_skip: 'Can be skipped',

      message: {
        delete_confirm: 'Are you sure you want to delete this message?'
      },

      md_editor: {
        label: 'Response (<a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener nofollow noreferrer">Markdown</a> text format):'
      },

      meeting: {
        action: 'Go to Event'
      },

      next: {
        one: 'Next'
      },

      physical_address_person: {
        hint: 'If it is the same as the registration address, leave the field empty'
      },

      postal_address_of_a_person: {
        hint: 'If it is the same as the residence address, leave the field empty'
      },

      teacher_comments: "Teacher's comments",

      test_execution_time: 'This test must be completed during',

      essay_execution_time: 'This assignment must be completed during',

      documents_library: 'Documents library',

      need_help: 'Need help',

      no_answer: 'Nothing is here.',

      no_answer_option: 'Nothing is here.',

      no_task: 'Nothing is here.',

      no_task_text: 'Nothing is here.',

      no_questions: 'Nothing is here.',

      no_question_text: 'Nothing is here.',

      or: 'Or',

      page_help: 'Page help',

      show: 'Show',

      start_survey: 'Start the survey',

      survey_unavailable: 'The survey is unavailable',

      survey_completed: 'The survey is completed',

      survey_pending: 'The survey has not started',

      survey_started: 'The survey is being conducted',

      survey_possible_was_only: 'The survey was available',

      survey_possible_will_be_only: 'The survey will be available',

      survey_possible_only: 'The survey is available',

      time_zone: 'Time zone',

      version_visually_impaired: 'Version for the visually impaired',

      work: 'Work',

      your_answer: 'Your answer',

      your_mark: 'Your assessment',

      your_time_expired: 'Your time has expired',

      library_catalog: 'Catalog',

      library_any_catalog: 'Any catalog',

      library_lecture_hall: 'Lecture hall',

      library_searching_by_author_title_year_keywords: 'Search by author, title, year, keywords',

      library_search: 'Search',

      library_advanced_search: 'Advanced search',

      library_author: 'Author',

      library_publication_year: 'Publication year',

      library_year: 'Year',

      library_place_of_publication: 'Place of publication',

      library_publisher: 'Publisher',

      library_udc: 'UDC',

      library_isbn: 'ISBN',

      library_enter_the_author: 'Enter the author',

      library_enter_the_publication_year: 'Enter the publication year',

      library_enter_the_place_of_publication: 'Enter the place of publication',

      library_enter_the_name_of_publisher: 'Enter the name of publisher',

      library_materials: 'Materials',

      library_all_materials: 'All materials',

      library_news: 'New',

      library_information: 'Information',

      library_documents: 'Library Documents',

      library_description: 'Description',

      library_comment: 'Comment',

      library_files: 'Files',

      library_accept: 'Accept',

      library_notification: 'These materials are placed in the system in accordance with <a href="http://www.consultant.ru/document/cons_doc_LAW_64629/2f837f48edd6ec8026f7b152a88d25f900d16947/" target="_blank" rel="noopener nofollow noreferrer">paragraph 6 of Article 1275 of the Civil Code of the Russian Federation</a>',

      library_forbidden_to_copy: 'It is forbidden to copy and distribute these materials, as well as use them for commercial purposes.',

      library_forbidden_to_transfer: 'It is forbidden to transfer access to the corporate electronic library to any third parties. Choose one answer.',
    },
    $sdo: {
      ...sdoTranslations
    },
  }
}
